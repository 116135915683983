import { throttle } from "lodash";

import { addAdsbRecord } from "./adsbRecorder";
import { getLocalAirTraffic } from "./getLocalAirTraffic";

import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import type { AdsbTraffic } from "@skydio/channels/src/adsb_traffic_pb";
import type { PingStationStatus } from "@skydio/channels/src/ping_station_status_pb";
import type { FlightPlayerState } from "../slice";
import type { AdsbRecorderSource } from "./adsbRecorder";

export const EXPIRATION_TIME = 3 * 1000; // [ms] delete state from vehicles from which we haven't heard in this time

const deleteExpiredMessages = (rawMessages: FlightPlayerState["airTrafficData"]["rawMessages"]) => {
  const now = Date.now();
  Object.keys(rawMessages).forEach(icaoAddress => {
    if (now - rawMessages[icaoAddress]?.receiveTime! > EXPIRATION_TIME) {
      try {
        delete rawMessages[icaoAddress];
      } catch (e) {
        console.warn(`Could not delete property ${icaoAddress} in object: ${e}`);
      }
    }
  });
};

// Use a throttled version of the deleteExpiredMessages function as we can be receiving ads-b messages at a high rate
// We need to set the trailing option to false to make sure the function is only executed when being inside the reducer, i.e. on the rising edge
// If the delete function gets called on the trailing edge of the wait time, the state might be frozen and we can't delete the properties
const deleteExpiredMessagesThrottled = throttle(deleteExpiredMessages, 1000, { trailing: false });

/**
 * Handler for ADS-B messages received from the Dock. Implemented in a separate module because it is
 * likely it will be moved to the fleet page in a future release.
 *
 * We store the latest message received from an airplane in a mapping from ICAO address to message. Messages are deleted once we have stopped receiving
 * information on the vehicle for EXPIRATION_TIME. The expiration check is done upon receiving new messages
 * but it is throttled
 *
 * TODO(Nacho): in order to support aircraft trajectory plotting, we should store a history of the messages received for each airplane. This
 * was initially done by storing them in the rawMessages mapping, but was proved to cause performance issues of the app, due to the high number
 * of messages received for each airplane. This should be handled either outside of redux in a mutable object, or by storing directly the trajectories
 * as ThreeJS geometries.
 */
export const handleAdsbTrafficMessage: CaseReducer<
  FlightPlayerState,
  PayloadAction<{ message: AdsbTraffic.AsObject; source: AdsbRecorderSource }>
> = (state, { payload: { message: adsbTrafficMessage, source } }) => {
  // If we are recording, save the message
  if (state.recordAdsbData) {
    addAdsbRecord({ message: adsbTrafficMessage, source });
  }
  const vehicles = adsbTrafficMessage.vehiclesList;
  const newVehiclesMapping: FlightPlayerState["airTrafficData"]["rawMessages"] = {};
  vehicles.forEach(adsbMessage => {
    const { icaoAddress } = adsbMessage;
    if (icaoAddress != null) {
      newVehiclesMapping[icaoAddress] = { ...adsbMessage, receiveTime: Date.now() };
    }
  });
  if (Object.keys(newVehiclesMapping).length > 0) {
    // Update state with new messages
    state.airTrafficData.rawMessages = {
      ...state.airTrafficData.rawMessages,
      ...newVehiclesMapping,
    };
    // Update traffic seen by drone
    state.airTrafficData.localTraffic = getLocalAirTraffic({
      airTrafficData: state.airTrafficData,
      vehicleAndCameraPoseLite: state.telemetry.vehicleAndCameraPoseLite,
      vehicleStatus: state.telemetry.vehicleStatus,
      trafficDisplaySettings: state.airTrafficData.displaySettings,
    });
  }
  // Remove expired messages
  deleteExpiredMessagesThrottled(state.airTrafficData.rawMessages);
};

/**
 * Handler for the ads-b station ping status messages. It stores the message, adding the time at which
 * it was received, in the redux store without any history
 */
export const handlePingStationStatusMessage: CaseReducer<FlightPlayerState, any> = (
  state,
  { payload: pingStatusMessage }: PayloadAction<PingStationStatus.AsObject>
) => {
  state.adsbStationState = { ...pingStatusMessage, receiveTime: Date.now() };
};
