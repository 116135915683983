// This file automatically generated by skymarshal.
// DO NOT MODIFY BY HAND!!!!
//
// From Source File: user_camera/lcmtypes/camera_control_old.lcm
import Long from "long";
import Decoder from "@skydio/lcm/src/decoder";
import Encoder from "@skydio/lcm/src/encoder";
import LcmEnumMsg from "@skydio/lcm/src/enum";

enum option_t {
  UNINITIALIZED = 0,
  INITIALIZED = 10,
  TO_RECORDING = 101,
  RECORDING = 11,
  TO_MOUNTED = 102,
  MOUNTED = 12,
  TO_STREAMING_ONLY = 103,
  STREAMING_ONLY = 13,
  TO_PREVIEW = 105,
  PREVIEW = 15,
}

export class user_camera_mode_t extends LcmEnumMsg {
  public static override option_t = option_t;
  private static _hash = Long.fromString("11999453130804877463", true);
  private static _packed_fingerprint?: Long;

  /** @internal */
  public static override _get_packed_fingerprint(): Long {
    if (user_camera_mode_t._packed_fingerprint === undefined) {
      user_camera_mode_t._packed_fingerprint = user_camera_mode_t._get_hash_recursive([]);
    }
    return user_camera_mode_t._packed_fingerprint;
  }

  /** @internal */
  public static override _get_hash_recursive(parents: any[]): Long {
    if (parents.indexOf(user_camera_mode_t) > -1) {
      return Long.fromNumber(0, true);
    }
    let tmp_hash = user_camera_mode_t._hash;
    tmp_hash = tmp_hash.shl(1).add(tmp_hash.shru(63));
    return tmp_hash;
  }

  public override decode(array: Uint8Array): user_camera_mode_t {
    const decoder = new Decoder(
      new DataView(array.buffer, array.byteOffset, array.byteLength)
    );
    const hash = decoder.decode_hash();
    if (!hash.equals(user_camera_mode_t._get_packed_fingerprint())) {
      console.error("Hash mismatch", hash, user_camera_mode_t._get_packed_fingerprint());
      throw new Error("Hash mismatch!");
    }
    this.decode_one(decoder);
    return this;
  }

  // Recursively decode a message with the supplied decoder.
  public override decode_one(decoder: Decoder): user_camera_mode_t {
    this.value = decoder.decode_int16_t();
    return this;
  }

  /** @internal */
  public _get_encoded_size(): number {
    return 2;
  }

  public override encode(): ArrayBufferLike {
    const total_size = this._get_encoded_size();
    const encoder = new Encoder(total_size);
    this.encode_one(encoder);
    return encoder.view.buffer;
  }

  public override encode_one(encoder: Encoder): void {
    encoder.encode_int16_t(this.value);
  }

  // static constructors
  static get UNINITIALIZED() {
    return new user_camera_mode_t(option_t.UNINITIALIZED);
  }
  static get INITIALIZED() {
    return new user_camera_mode_t(option_t.INITIALIZED);
  }
  static get TO_RECORDING() {
    return new user_camera_mode_t(option_t.TO_RECORDING);
  }
  static get RECORDING() {
    return new user_camera_mode_t(option_t.RECORDING);
  }
  static get TO_MOUNTED() {
    return new user_camera_mode_t(option_t.TO_MOUNTED);
  }
  static get MOUNTED() {
    return new user_camera_mode_t(option_t.MOUNTED);
  }
  static get TO_STREAMING_ONLY() {
    return new user_camera_mode_t(option_t.TO_STREAMING_ONLY);
  }
  static get STREAMING_ONLY() {
    return new user_camera_mode_t(option_t.STREAMING_ONLY);
  }
  static get TO_PREVIEW() {
    return new user_camera_mode_t(option_t.TO_PREVIEW);
  }
  static get PREVIEW() {
    return new user_camera_mode_t(option_t.PREVIEW);
  }

  public override value: option_t;

  constructor(value?: option_t) {
    super();
    this.value = value ?? option_t.UNINITIALIZED;
  }

  public override get name(): string {
    return option_t[this.value]!;
  }
}