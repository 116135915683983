import type { AdsbTraffic } from "@skydio/channels/src/adsb_traffic_pb";

export enum AdsbRecorderSource {
  /**
   * ADS-B messages received from the vehicle through Skybus
   */
  SKYBUS_ADSB_IN_PB = "SKYBUS_ADSB_IN_PB",
  /**
   * ADS-B messages received from the dock or external radio through Skybus
   */
  SKYBUS_ADSB_TRAFFIC_PB = "SKYBUS_ADSB_TRAFFIC_PB",
  /**
   * ADS-B messages received from the dock through IOT API
   */
  IOT_NEAREST_DOCK = "IOT_NEAREST_DOCK",
  /**
   * ADS-B messages received from a simulation environment
   */
  SIMULATION = "SIMULATION",
}
interface AdsbRecorderEntry {
  recordedAt: string;
  message: AdsbTraffic.AsObject;
  source: AdsbRecorderSource;
}

/**
 * Singleton that records the ADS-B data from the flight player.
 * Exposes methods add a message and to reset the recording.
 */
let adsbRecords: AdsbRecorderEntry[] = [];

const addAdsbRecord = ({
  message,
  source,
}: {
  message: AdsbTraffic.AsObject;
  source: AdsbRecorderSource;
}) => {
  adsbRecords.push({
    recordedAt: new Date().toISOString(),
    message,
    source,
  });
};

const resetAdsbRecord = () => {
  adsbRecords = [];
};

export { adsbRecords, addAdsbRecord, resetAdsbRecord };
