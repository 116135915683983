import { createSlice } from "@reduxjs/toolkit";

import { teleopActions } from "state/teleop/slice";

import { pilotActions } from "../pilot/slice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { WorkflowLayout } from "@skydio/channels/src/ui_workflow_pb";

export type WorkflowState = {
  workflow?: WorkflowLayout.AsObject;
  currentSkillKey?: string;
};

const initialState = {} as WorkflowState;

const { reducer, actions: workflowActions } = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    handleWorkflowUI(state, { payload }: PayloadAction<WorkflowLayout.AsObject>) {
      state.workflow = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(pilotActions.handleSkillStatus, (state, { payload }) => {
      // Keep track of when the active skill changes, clear workflow state when it does
      // Otherwise the workflow UI will stick around when we switch to non-workflow skills
      if (payload.skillKey !== state.currentSkillKey) {
        state.workflow = undefined;
        state.currentSkillKey = payload.skillKey;
      }
    });
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    });
  },
});

export { workflowActions };
export default reducer;
