import { createSlice } from "@reduxjs/toolkit";
import { omitBy } from "lodash";

import { teleopActions } from "state/teleop/slice";
import { getStarterNonce } from "utils/common";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { UiAck } from "@skydio/pbtypes/pbtypes/gen/skills/ui_ack_pb";
import type { SkillSettingTypes } from "utils/pilot";
import type { PilotInteractablesState, WorkflowRequest } from "./types";

const initialState: PilotInteractablesState = {
  buttons: {},
  promptButtons: {},
  radios: {},
  sliders: {},
  toggles: {},
  checkboxes: {},
  textFields: {},
  latestNonce: getStarterNonce(),
  isTextFieldFocused: false,
};

const { reducer, actions: pilotInteractablesActions } = createSlice({
  name: "pilotInteractables",
  initialState,
  reducers: {
    handleButtonPress(state, { payload: buttonId }: PayloadAction<string>) {
      if (!state.buttons[buttonId]) {
        state.buttons[buttonId] = {
          buttonId,
          nonce: state.latestNonce++,
        };
      }
    },
    handleAck(state, { payload }: PayloadAction<UiAck>) {
      const nonce = payload.getNonce();
      state.buttons = omitBy(state.buttons, b => b.nonce === nonce);
      state.radios = omitBy(state.radios, r => r.nonce === nonce);
      state.sliders = omitBy(state.sliders, s => s.nonce === nonce);
      state.toggles = omitBy(state.toggles, t => t.nonce === nonce);
      state.checkboxes = omitBy(state.checkboxes, t => t.nonce === nonce);
      state.textFields = omitBy(state.textFields, t => t.nonce === nonce);
    },
    handlePromptButtonPress(state, { payload: buttonId }: PayloadAction<string>) {
      if (!state.promptButtons[buttonId]) {
        state.promptButtons[buttonId] = {
          buttonId,
          nonce: state.latestNonce++,
        };
      }
    },
    handlePromptButtonAck(state, { payload }: PayloadAction<UiAck>) {
      state.promptButtons = omitBy(state.promptButtons, b => b.nonce === payload.getNonce());
    },
    handleSettingInteraction(
      state,
      {
        payload: { id, value, type },
      }: PayloadAction<{ id: string; value: string | boolean; type: SkillSettingTypes }>
    ) {
      let interactables;
      switch (type) {
        case "radio":
          interactables = state.radios;
          break;
        case "slider":
          interactables = state.sliders;
          break;
        case "toggle":
          interactables = state.toggles;
          break;
        case "checkbox":
          interactables = state.checkboxes;
          break;
        case "textField":
          interactables = state.textFields;
      }
      const interactable = interactables[id];
      if (!interactable || interactable.value !== value) {
        interactables[id] = {
          id,
          nonce: state.latestNonce++,
          value,
        };
      }
    },
    handleWorkflowClientRequest(state, { payload }: PayloadAction<Omit<WorkflowRequest, "nonce">>) {
      state.workflowRequest = { ...payload, nonce: state.latestNonce++ };
    },

    clearWorkflowRequest(state) {
      state.workflowRequest = undefined;
    },

    handleIsTextFieldFocused(state, { payload }: PayloadAction<boolean>) {
      state.isTextFieldFocused = payload;
    },
  },
  extraReducers: builder =>
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    }),
});

export { pilotInteractablesActions };
export default reducer;
